import axios from '@/plugins/axios'

export default {

  async list (data) {
    const parametros = new URLSearchParams(data).toString()
    return await axios.get(`sectors?${parametros}`)
  },

  async get (id) {
    return await axios.get(`sectors/${id}`)
  },

  async insert (data) {
    return await axios.post('sectors', data)
  },

  async update (id, data) {
    return await axios.put(`sectors/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`sectors/${id}`)
  },

}
